import { createAction, props } from '@ngrx/store';
import { BasicResponse, ResponseWrapper } from 'common';
import { CommonSearchReq, MetersFilters, PersonalizationFilters, QueriesSearchResults, SaveGlobalSettingsPayload } from './interfaces';

// INNER
export enum CustomerDataPlatformInnerActionTypes {
    GetGlobalSettingsSuccess ='[Global Settings] Get Global Settings Success',
    GetGlobalSettingsError = '[Global Settings] Get Global Settings Error',
    SaveGlobalSettingsSuccess = '[Global Settings] Save Global Settings Success',
    SaveGlobalSettingsError = '[Global Settings] Save Global Settings Error',
    SearchQueriesSuccess = '[CDP] Search Queries Success',
    SearchQueriesError = '[CDP] Search Queries Error',
    CheckCustomerIdSuccess = '[CdpMeters] Check Customer Id Success',
    CheckCustomerIdError = '[CdpMeters] Check Customer Id Error',
}

//HTTP
export enum CustomerDataPlatformHttpActionTypes {
    GetGlobalSettings = '[Global Settings] Get Global Settings',
    SaveGlobalSettings = '[Global Settings] Save Global Settings',
    SearchQueries = '[CDP] Search Queries',
    ClearSearchQueriesResults = '[CDP] Clear Search Queries Results',
    CheckCustomerId = '[CdpMeters] Check Customer Id',
    StorePersonalizationFilters = '[CdpPersonalization] Store latest Personalization Dashboard filter values',
    StoreMetersFilters = '[CdpMeters] Store latest Meters Dashboard filter values',
    ClearStoreValue = '[Cdp] Clear Store Value by key'
}

//HTTP
export const GetGlobalSettings = createAction(CustomerDataPlatformHttpActionTypes.GetGlobalSettings);
export const SaveGlobalSettings = createAction(CustomerDataPlatformHttpActionTypes.SaveGlobalSettings, props<{ payload: SaveGlobalSettingsPayload}>());
export const SearchQueries = createAction(CustomerDataPlatformHttpActionTypes.SearchQueries, props<{ payload: CommonSearchReq }>());
export const ClearSearchQueriesResults = createAction(CustomerDataPlatformHttpActionTypes.ClearSearchQueriesResults);
export const CheckCustomerId = createAction(CustomerDataPlatformHttpActionTypes.CheckCustomerId, props<{ payload: { environmentId?: number, customerId: number } }>());
export const StorePersonalizationFilters = createAction(CustomerDataPlatformHttpActionTypes.StorePersonalizationFilters, props<{ payload: PersonalizationFilters }>());
export const StoreMetersFilters = createAction(CustomerDataPlatformHttpActionTypes.StoreMetersFilters, props<{ payload: MetersFilters }>());
export const ClearStoreValue = createAction(CustomerDataPlatformHttpActionTypes.ClearStoreValue, props<{ key: string }>());

// INNER
export const GetGlobalSettingsSuccess = createAction(CustomerDataPlatformInnerActionTypes.GetGlobalSettingsSuccess, props<{ payload: ResponseWrapper<SaveGlobalSettingsPayload> }>());
export const GetGlobalSettingsError = createAction(CustomerDataPlatformInnerActionTypes.GetGlobalSettingsError, props<{ payload: BasicResponse }>());
export const SaveGlobalSettingsSuccess = createAction(CustomerDataPlatformInnerActionTypes.SaveGlobalSettingsSuccess, props<{ payload: BasicResponse }>());
export const SaveGlobalSettingsError = createAction(CustomerDataPlatformInnerActionTypes.SaveGlobalSettingsError, props<{ payload: BasicResponse }>());
export const SearchQueriesSuccess = createAction(CustomerDataPlatformInnerActionTypes.SearchQueriesSuccess, props<{ payload: ResponseWrapper<QueriesSearchResults> }>());
export const SearchQueriesError = createAction(CustomerDataPlatformInnerActionTypes.SearchQueriesError, props<{ error: BasicResponse }>());
export const CheckCustomerIdSuccess = createAction(CustomerDataPlatformInnerActionTypes.CheckCustomerIdSuccess, props<{ payload: { encryptedCustomerId: string } }>());
export const CheckCustomerIdError = createAction(CustomerDataPlatformInnerActionTypes.CheckCustomerIdError, props<{ error: BasicResponse }>());